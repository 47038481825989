// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.push([module.id, "@import url(//at.alicdn.com/t/font_2801612_u20wa8bez.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(//at.alicdn.com/t/font_1413662_wwmybr9gcbr.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#loader-wrapper{height:100%;left:0;position:fixed;top:0;width:100%;z-index:999999}#loader{animation:spin 2s linear infinite;border:3px solid transparent;border-radius:50%;border-top-color:#165dff;display:block;height:100px;left:50%;margin:-50px 0 0 -50px;position:relative;top:50%;width:100px;z-index:1001}#loader:before{animation:spin 3s linear infinite;bottom:5px;left:5px;right:5px;top:5px}#loader:after,#loader:before{border:3px solid transparent;border-radius:50%;border-top-color:#165dff;content:\"\";position:absolute}#loader:after{animation:spin 1.5s linear infinite;bottom:15px;left:15px;right:15px;top:15px}@keyframes spin{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}#loader-wrapper .loader-section{background:#f7f8fa;height:100%;position:fixed;top:0;transform:translateX(0);width:51%;z-index:1000}#loader-wrapper .loader-section.section-left{left:0}#loader-wrapper .loader-section.section-right{right:0}#loader-wrapper .load_title{color:#000;font-family:\"Open Sans\";font-size:19px;line-height:30px;opacity:1;position:absolute;text-align:center;top:60%;width:100%;z-index:9999999999999}#loader-wrapper .load_title span{color:#fff;font-size:13px;font-style:italic;font-weight:400;opacity:.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
