import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _488f2bfa = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _190cb21d = () => interopDefault(import('../pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _3e0196ce = () => interopDefault(import('../pages/appCompany.vue' /* webpackChunkName: "pages/appCompany" */))
const _4f686f6d = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _5ef87c02 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _3f8e8dd0 = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _7b2f8e11 = () => interopDefault(import('../pages/development/index.vue' /* webpackChunkName: "pages/development/index" */))
const _90af4b36 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1f94c52c = () => interopDefault(import('../pages/forget.vue' /* webpackChunkName: "pages/forget" */))
const _2af01e1f = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _22560454 = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _2e264b34 = () => interopDefault(import('../pages/lecturer/index.vue' /* webpackChunkName: "pages/lecturer/index" */))
const _fd600508 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0bd2f468 = () => interopDefault(import('../pages/permission.vue' /* webpackChunkName: "pages/permission" */))
const _0c474c40 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _e6940f3a = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _55ad927a = () => interopDefault(import('../pages/tpl.vue' /* webpackChunkName: "pages/tpl" */))
const _395d44e5 = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _5b197a7c = () => interopDefault(import('../pages/development/estimate/index.vue' /* webpackChunkName: "pages/development/estimate/index" */))
const _54b7a1b1 = () => interopDefault(import('../pages/development/qualification/index.vue' /* webpackChunkName: "pages/development/qualification/index" */))
const _cce23fea = () => interopDefault(import('../pages/development/study/index.vue' /* webpackChunkName: "pages/development/study/index" */))
const _3ed37b22 = () => interopDefault(import('../pages/home/rank.vue' /* webpackChunkName: "pages/home/rank" */))
const _3eea6eac = () => interopDefault(import('../pages/integral/mall.vue' /* webpackChunkName: "pages/integral/mall" */))
const _c1b3abdc = () => interopDefault(import('../pages/investigate/view.vue' /* webpackChunkName: "pages/investigate/view" */))
const _260e7508 = () => interopDefault(import('../pages/watch/catePanel.vue' /* webpackChunkName: "pages/watch/catePanel" */))
const _5cda3a18 = () => interopDefault(import('../pages/watch/course.vue' /* webpackChunkName: "pages/watch/course" */))
const _fdf8b410 = () => interopDefault(import('../pages/watch/practice.vue' /* webpackChunkName: "pages/watch/practice" */))
const _1c70bfc2 = () => interopDefault(import('../pages/watch/task.vue' /* webpackChunkName: "pages/watch/task" */))
const _8caa699c = () => interopDefault(import('../pages/account/exam/detail.vue' /* webpackChunkName: "pages/account/exam/detail" */))
const _bd6b1174 = () => interopDefault(import('../pages/account/exam/examQuestion.vue' /* webpackChunkName: "pages/account/exam/examQuestion" */))
const _4a30975a = () => interopDefault(import('../pages/account/exam/examSuccess.vue' /* webpackChunkName: "pages/account/exam/examSuccess" */))
const _233d1f32 = () => interopDefault(import('../pages/account/exam/record.vue' /* webpackChunkName: "pages/account/exam/record" */))
const _07648de8 = () => interopDefault(import('../pages/account/plan/detail.vue' /* webpackChunkName: "pages/account/plan/detail" */))
const _59531b40 = () => interopDefault(import('../pages/development/estimate/batchEvaluation.vue' /* webpackChunkName: "pages/development/estimate/batchEvaluation" */))
const _10969f02 = () => interopDefault(import('../pages/development/estimate/evaluation.vue' /* webpackChunkName: "pages/development/estimate/evaluation" */))
const _514bf7fa = () => interopDefault(import('../pages/development/estimate/report.vue' /* webpackChunkName: "pages/development/estimate/report" */))
const _4a827fee = () => interopDefault(import('../pages/account/plan/task/add.vue' /* webpackChunkName: "pages/account/plan/task/add" */))
const _2ab1a514 = () => interopDefault(import('../pages/account/plan/task/detail.vue' /* webpackChunkName: "pages/account/plan/task/detail" */))
const _7d8b3a5d = () => interopDefault(import('../pages/knowledge/offcourse/components/AssessAttachment.vue' /* webpackChunkName: "pages/knowledge/offcourse/components/AssessAttachment" */))
const _0ae3f9ae = () => interopDefault(import('../pages/account/exam/_id.vue' /* webpackChunkName: "pages/account/exam/_id" */))
const _120bba8f = () => interopDefault(import('../pages/account/task/_id.vue' /* webpackChunkName: "pages/account/task/_id" */))
const _2c9bab4a = () => interopDefault(import('../pages/integral/goods/_id.vue' /* webpackChunkName: "pages/integral/goods/_id" */))
const _0187349a = () => interopDefault(import('../pages/investigate/analyze/_id.vue' /* webpackChunkName: "pages/investigate/analyze/_id" */))
const _7b5f6c98 = () => interopDefault(import('../pages/knowledge/course/_id.vue' /* webpackChunkName: "pages/knowledge/course/_id" */))
const _48043f12 = () => interopDefault(import('../pages/knowledge/offcourse/_id.vue' /* webpackChunkName: "pages/knowledge/offcourse/_id" */))
const _525e60c6 = () => interopDefault(import('../pages/knowledge/project/_id.vue' /* webpackChunkName: "pages/knowledge/project/_id" */))
const _7ed33644 = () => interopDefault(import('../pages/lecturer/view/_id.vue' /* webpackChunkName: "pages/lecturer/view/_id" */))
const _07bd5434 = () => interopDefault(import('../pages/live/watch/_watch.vue' /* webpackChunkName: "pages/live/watch/_watch" */))
const _b4144ce4 = () => interopDefault(import('../pages/redirect/_path.vue' /* webpackChunkName: "pages/redirect/_path" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _488f2bfa,
    name: "account___zh"
  }, {
    path: "/agreement",
    component: _190cb21d,
    name: "agreement___zh"
  }, {
    path: "/appCompany",
    component: _3e0196ce,
    name: "appCompany___zh"
  }, {
    path: "/certificate",
    component: _4f686f6d,
    name: "certificate___zh"
  }, {
    path: "/community",
    component: _5ef87c02,
    name: "community___zh"
  }, {
    path: "/company",
    component: _3f8e8dd0,
    name: "company___zh"
  }, {
    path: "/development",
    component: _7b2f8e11,
    name: "development___zh"
  }, {
    path: "/en",
    component: _90af4b36,
    name: "index___en"
  }, {
    path: "/forget",
    component: _1f94c52c,
    name: "forget___zh"
  }, {
    path: "/home",
    component: _2af01e1f,
    name: "home___zh"
  }, {
    path: "/knowledge",
    component: _22560454,
    name: "knowledge___zh"
  }, {
    path: "/lecturer",
    component: _2e264b34,
    name: "lecturer___zh"
  }, {
    path: "/login",
    component: _fd600508,
    name: "login___zh"
  }, {
    path: "/permission",
    component: _0bd2f468,
    name: "permission___zh"
  }, {
    path: "/register",
    component: _0c474c40,
    children: [{
      path: "",
      component: _e6940f3a,
      name: "register___zh"
    }]
  }, {
    path: "/tpl",
    component: _55ad927a,
    name: "tpl___zh"
  }, {
    path: "/watch",
    component: _395d44e5,
    name: "watch___zh"
  }, {
    path: "/development/estimate",
    component: _5b197a7c,
    name: "development-estimate___zh"
  }, {
    path: "/development/qualification",
    component: _54b7a1b1,
    name: "development-qualification___zh"
  }, {
    path: "/development/study",
    component: _cce23fea,
    name: "development-study___zh"
  }, {
    path: "/en/account",
    component: _488f2bfa,
    name: "account___en"
  }, {
    path: "/en/agreement",
    component: _190cb21d,
    name: "agreement___en"
  }, {
    path: "/en/appCompany",
    component: _3e0196ce,
    name: "appCompany___en"
  }, {
    path: "/en/certificate",
    component: _4f686f6d,
    name: "certificate___en"
  }, {
    path: "/en/community",
    component: _5ef87c02,
    name: "community___en"
  }, {
    path: "/en/company",
    component: _3f8e8dd0,
    name: "company___en"
  }, {
    path: "/en/development",
    component: _7b2f8e11,
    name: "development___en"
  }, {
    path: "/en/forget",
    component: _1f94c52c,
    name: "forget___en"
  }, {
    path: "/en/home",
    component: _2af01e1f,
    name: "home___en"
  }, {
    path: "/en/knowledge",
    component: _22560454,
    name: "knowledge___en"
  }, {
    path: "/en/lecturer",
    component: _2e264b34,
    name: "lecturer___en"
  }, {
    path: "/en/login",
    component: _fd600508,
    name: "login___en"
  }, {
    path: "/en/permission",
    component: _0bd2f468,
    name: "permission___en"
  }, {
    path: "/en/register",
    component: _0c474c40,
    children: [{
      path: "",
      component: _e6940f3a,
      name: "register___en"
    }]
  }, {
    path: "/en/tpl",
    component: _55ad927a,
    name: "tpl___en"
  }, {
    path: "/en/watch",
    component: _395d44e5,
    name: "watch___en"
  }, {
    path: "/home/rank",
    component: _3ed37b22,
    name: "home-rank___zh"
  }, {
    path: "/integral/mall",
    component: _3eea6eac,
    name: "integral-mall___zh"
  }, {
    path: "/investigate/view",
    component: _c1b3abdc,
    name: "investigate-view___zh"
  }, {
    path: "/watch/catePanel",
    component: _260e7508,
    name: "watch-catePanel___zh"
  }, {
    path: "/watch/course",
    component: _5cda3a18,
    name: "watch-course___zh"
  }, {
    path: "/watch/practice",
    component: _fdf8b410,
    name: "watch-practice___zh"
  }, {
    path: "/watch/task",
    component: _1c70bfc2,
    name: "watch-task___zh"
  }, {
    path: "/account/exam/detail",
    component: _8caa699c,
    name: "account-exam-detail___zh"
  }, {
    path: "/account/exam/examQuestion",
    component: _bd6b1174,
    name: "account-exam-examQuestion___zh"
  }, {
    path: "/account/exam/examSuccess",
    component: _4a30975a,
    name: "account-exam-examSuccess___zh"
  }, {
    path: "/account/exam/record",
    component: _233d1f32,
    name: "account-exam-record___zh"
  }, {
    path: "/account/plan/detail",
    component: _07648de8,
    name: "account-plan-detail___zh"
  }, {
    path: "/development/estimate/batchEvaluation",
    component: _59531b40,
    name: "development-estimate-batchEvaluation___zh"
  }, {
    path: "/development/estimate/evaluation",
    component: _10969f02,
    name: "development-estimate-evaluation___zh"
  }, {
    path: "/development/estimate/report",
    component: _514bf7fa,
    name: "development-estimate-report___zh"
  }, {
    path: "/en/development/estimate",
    component: _5b197a7c,
    name: "development-estimate___en"
  }, {
    path: "/en/development/qualification",
    component: _54b7a1b1,
    name: "development-qualification___en"
  }, {
    path: "/en/development/study",
    component: _cce23fea,
    name: "development-study___en"
  }, {
    path: "/en/home/rank",
    component: _3ed37b22,
    name: "home-rank___en"
  }, {
    path: "/en/integral/mall",
    component: _3eea6eac,
    name: "integral-mall___en"
  }, {
    path: "/en/investigate/view",
    component: _c1b3abdc,
    name: "investigate-view___en"
  }, {
    path: "/en/watch/catePanel",
    component: _260e7508,
    name: "watch-catePanel___en"
  }, {
    path: "/en/watch/course",
    component: _5cda3a18,
    name: "watch-course___en"
  }, {
    path: "/en/watch/practice",
    component: _fdf8b410,
    name: "watch-practice___en"
  }, {
    path: "/en/watch/task",
    component: _1c70bfc2,
    name: "watch-task___en"
  }, {
    path: "/account/plan/task/add",
    component: _4a827fee,
    name: "account-plan-task-add___zh"
  }, {
    path: "/account/plan/task/detail",
    component: _2ab1a514,
    name: "account-plan-task-detail___zh"
  }, {
    path: "/en/account/exam/detail",
    component: _8caa699c,
    name: "account-exam-detail___en"
  }, {
    path: "/en/account/exam/examQuestion",
    component: _bd6b1174,
    name: "account-exam-examQuestion___en"
  }, {
    path: "/en/account/exam/examSuccess",
    component: _4a30975a,
    name: "account-exam-examSuccess___en"
  }, {
    path: "/en/account/exam/record",
    component: _233d1f32,
    name: "account-exam-record___en"
  }, {
    path: "/en/account/plan/detail",
    component: _07648de8,
    name: "account-plan-detail___en"
  }, {
    path: "/en/development/estimate/batchEvaluation",
    component: _59531b40,
    name: "development-estimate-batchEvaluation___en"
  }, {
    path: "/en/development/estimate/evaluation",
    component: _10969f02,
    name: "development-estimate-evaluation___en"
  }, {
    path: "/en/development/estimate/report",
    component: _514bf7fa,
    name: "development-estimate-report___en"
  }, {
    path: "/knowledge/offcourse/components/AssessAttachment",
    component: _7d8b3a5d,
    name: "knowledge-offcourse-components-AssessAttachment___zh"
  }, {
    path: "/en/account/plan/task/add",
    component: _4a827fee,
    name: "account-plan-task-add___en"
  }, {
    path: "/en/account/plan/task/detail",
    component: _2ab1a514,
    name: "account-plan-task-detail___en"
  }, {
    path: "/en/knowledge/offcourse/components/AssessAttachment",
    component: _7d8b3a5d,
    name: "knowledge-offcourse-components-AssessAttachment___en"
  }, {
    path: "/",
    component: _90af4b36,
    name: "index___zh"
  }, {
    path: "/en/account/exam/:id?",
    component: _0ae3f9ae,
    name: "account-exam-id___en"
  }, {
    path: "/en/account/task/:id?",
    component: _120bba8f,
    name: "account-task-id___en"
  }, {
    path: "/en/integral/goods/:id?",
    component: _2c9bab4a,
    name: "integral-goods-id___en"
  }, {
    path: "/en/investigate/analyze/:id?",
    component: _0187349a,
    name: "investigate-analyze-id___en"
  }, {
    path: "/en/knowledge/course/:id?",
    component: _7b5f6c98,
    name: "knowledge-course-id___en"
  }, {
    path: "/en/knowledge/offcourse/:id?",
    component: _48043f12,
    name: "knowledge-offcourse-id___en"
  }, {
    path: "/en/knowledge/project/:id?",
    component: _525e60c6,
    name: "knowledge-project-id___en"
  }, {
    path: "/en/lecturer/view/:id?",
    component: _7ed33644,
    name: "lecturer-view-id___en"
  }, {
    path: "/en/live/watch/:watch?",
    component: _07bd5434,
    name: "live-watch-watch___en"
  }, {
    path: "/account/exam/:id?",
    component: _0ae3f9ae,
    name: "account-exam-id___zh"
  }, {
    path: "/account/task/:id?",
    component: _120bba8f,
    name: "account-task-id___zh"
  }, {
    path: "/en/redirect/:path?",
    component: _b4144ce4,
    name: "redirect-path___en"
  }, {
    path: "/integral/goods/:id?",
    component: _2c9bab4a,
    name: "integral-goods-id___zh"
  }, {
    path: "/investigate/analyze/:id?",
    component: _0187349a,
    name: "investigate-analyze-id___zh"
  }, {
    path: "/knowledge/course/:id?",
    component: _7b5f6c98,
    name: "knowledge-course-id___zh"
  }, {
    path: "/knowledge/offcourse/:id?",
    component: _48043f12,
    name: "knowledge-offcourse-id___zh"
  }, {
    path: "/knowledge/project/:id?",
    component: _525e60c6,
    name: "knowledge-project-id___zh"
  }, {
    path: "/lecturer/view/:id?",
    component: _7ed33644,
    name: "lecturer-view-id___zh"
  }, {
    path: "/live/watch/:watch?",
    component: _07bd5434,
    name: "live-watch-watch___zh"
  }, {
    path: "/redirect/:path?",
    component: _b4144ce4,
    name: "redirect-path___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
